import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

export default ({ data, location, ...props }) => {
    return (
        <SeoPage
            location={location}
            title="L’alcool au volant en bateau – quelles sont les conséquences?"
            description="Accusé d’alcool au volant d’un bateau? Défendez-vous avec l’aide d’un avocat criminaliste spécialisé!"
            fr={undefined}
            lawType="">
            <p>
                Par une chaude journée d’été sur un lac ensoleillé, vous avez
                malheureusement excédé la limite d’alcool permise sur une
                embarcation nautique?{' '}
            </p>
            <p>
                C’est une infraction plus commune qu’on ne le pense,
                malheureusement! En effet, l’alcool au volant en bateau est une
                infraction punie par le Code criminel, et contre laquelle vous
                devez absolument vous défendre. Mais comment s’en défaire, et
                quelles en sont les conséquences?{' '}
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Conséquences de l’alcool au volant en bateau"
                    alt="Une image contenant ciel, personne, sport, extérieur"
                />
            </p>
            <p>
                Avocatsalcoolauvolant.ca vous explique en détail l’impact d’une
                condamnation pour une infraction d’alcool en bateau dans les
                lignes qui suivent. Mieux encore, on vous réfère gratuitement
                aux bons avocats criminalistes pour vous défendre!{' '}
            </p>
            <h2>
                Consommer de l’alcool en conduisant un bateau, est-ce légal?
            </h2>
            <p>
                Il n’est pas interdit de consommer de l’alcool à bord d’un
                bateau ou d’une embarcation nautique. En effet, nulle part dans
                le Code criminel est-il mentionné que la consommation d’alcool
                sur une embarcation est punissable par la loi.
            </p>
            <p>
                <strong>Mais ATTENTION</strong> – La consommation d’alcool doit
                rester dans les limites permises par la loi. C’est donc dire que
                la consommation d’alcool à la barre d’un bateau deviendra
                criminelle si le taux d’alcool dans le sang dépasse les 80mg
                d’alcool par 100ml de sang!<strong> </strong>Le fameux « 0.08 »
                s’applique autant en voiture qu’en bateau.{' '}
            </p>
            <p>
                Le fait de dépasser la limite permise pour effet de vous
                sanctionner d’un casier judiciaire, et de suspendre votre permis
                de conduire automobile. Voyez en détail les conséquences d’une
                telle infraction juste ici.{' '}
            </p>
            <h2>
                Conduire un bateau avec les facultés affaiblies : les
                conséquences!{' '}
            </h2>
            <p>
                <strong>
                    L’alcool au volant d’un bateau entraîne un casier
                    judiciaire, comme en voiture!{' '}
                </strong>
            </p>
            <p>
                <strong>
                    <Img
                        fluid={data.img1.childImageSharp.fluid}
                        title="Conduire un bateau avec les facultés affaiblies"
                        alt="Une image contenant eau, extérieur, bateau, rouge"
                    />
                </strong>
            </p>
            <p>
                Et le principal élément qui fera varier la conséquence rattachée
                à cette infraction, c’est la récidive. Le fait d’avoir déjà une
                ou plusieurs condamnations pour une infraction d’alcool au
                volant rendra les conséquences d’autant plus sévères.{' '}
            </p>
            <p>
                <strong>
                    Voici les peines minimales lors d’une infraction reliée à
                    l’alcool au volant, y compris en bateau!{' '}
                </strong>
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            1<sup>ère</sup> infraction – Alcool au volant en
                            bateau
                        </p>
                    </td>
                    <td>
                        <p>Amende de 1000$ </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            2<sup>ième</sup> infraction - Alcool au volant en
                            bateau
                        </p>
                    </td>
                    <td>
                        <p>Emprisonnement de 30 jours </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            3<sup>ième</sup> infraction – Alcool au volant en
                            bateau
                        </p>
                    </td>
                    <td>
                        <p>Emprisonnement de 120 jours </p>
                    </td>
                </tr>
            </table>
            <p>
                L’alcool en bateau peut également vous faire perdre votre permis
                de conduire automobile! L’infraction commise sur l’eau a donc
                des répercussions jusque sur la terre ferme, alors il c’est une
                accusation qu’il faut prendre au sérieux.{' '}
            </p>
            <h2>
                Que faire en cas d’accusation d’alcool au volant d’un bateau?{' '}
            </h2>
            <h3>Consulter un avocat en droit criminel </h3>
            <p>
                La conduite avec les facultés affaiblies d’une embarcation
                nautique est une infraction punissable par le Code criminel. Le
                fait d’être accusé d’une telle infraction devrait vous inciter à
                consulter un avocat criminaliste spécialisé sans délai.{' '}
            </p>
            <h3>Respecter la suspension de permis de 90 jours </h3>
            <p>
                Avant même d’être reconnu coupable d’alcool au volant d’un
                bateau, votre permis de conduire automobile sera suspendu
                automatiquement pendant une période de 90 jours. Il est
                pratiquement impossible d’éviter cette suspension, alors ce
                délai doit être respecté.{' '}
            </p>
            <h3>Demander un permis de conduire restreint </h3>
            <p>
                Lorsque vous êtes reconnu coupable d’alcool au volant, votre
                permis de conduire est d’abord suspendu, et ensuite révoqué.
                Pour récupérer le droit de conduire pendant cette période
                d’interdiction, il faudra demander un permis restreint, ce qui
                nécessite l’assistance d’un avocat.{' '}
            </p>
            <h2>
                Alcool en bateau : Trouvez rapidement un avocat pour vous
                défendre!
            </h2>
            <p>
                L’alcool au volant d’un bateau, c’est infraction tout aussi
                grave que la conduite d’une automobile avec les facultés
                affaiblies par l’alcool. C’est pourquoi vous avez également
                intérêt à consulter un avocat criminaliste qualifié pour vous
                défendre, et ce sans délai.{' '}
            </p>
            <p>
                Vous avez cogné à la bonne porte pour trouver un tel avocat, car
                on est en mesure de vous mettre en contact rapidement et
                gratuitement avec un criminaliste d’expérience dans votre
                région, le tout sans engagement!{' '}
            </p>
            <p> </p>
        </SeoPage>
    )
}

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "alcool-volant-bateau/alcool-volant-bateau-consequences.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "alcool-volant-bateau/conduire-bateau-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
